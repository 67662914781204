function DownArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 244 512.89"
      style={{ height: "100px", fill: "#AFAFAF", width: "30px" }}
    >
      <path
        fillRule="nonzero"
        d="m23.95 353.26 81.16 93.05L105.52 0h31.8l-.41 446.3 83.14-95.08L244 372.17 120.95 512.89 0 374.21z"
      />
    </svg>
  );
}

export default DownArrowIcon;
