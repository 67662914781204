import { TCiqLogHeader } from "components/ciq-grid-view-wrapper/model";

function CiqLogPageHeader({ entityName, items }: TCiqLogHeader) {
  return (
    <div className="p-2 flex justify-between items-center">
      <div className="font-semibold text-sm uppercase">{entityName}</div>
      <div className="flex space-x-2 items-center">{items}</div>
    </div>
  );
}
export default CiqLogPageHeader;
